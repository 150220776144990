<template>
  <b-container fluid>
    <b-row>
      <b-col>
          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="circular.org_id">
              <div class="titleDiv">
                <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('portal.nomination_details') }}</span><br>
              </div>
          </list-report-head>
      </b-col>
    </b-row>
      <b-col lg="12">
        <b-row>
          <b-col lg="12" sm="12" class="p-0">
              <b-table-simple small bordered>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ circular.circular_memo_no }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.fiscal_year_bn : circular.fiscal_year }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('org_pro.office_type') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.office_type_bn : circular.office_type }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('org_pro.office_namel') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? circular.office_bn : circular.office }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.type') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.training_type_bn : circular.training_type }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.category') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? circular.training_category_bn : circular.training_category }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.tr_title') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.training_title_bn : circular.training_title_en }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_iabm.batch_no') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? circular.batch_name_bn : circular.batch_name }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('globalTrans.start_date') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ circular.training_start_date | dateFormat }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.end_date') }}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ circular.training_end_date | dateFormat }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left bg-light">{{ $t('elearning_config.remarks') }}</b-th>
                      <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ? circular.remarks_bn : circular.remarks }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{$t('globalTrans.attachment')}}</b-th>
                      <b-td style="width:65%%;" class="text-left">
                      <b v-if="circular.upload_guidelines">
                          <!-- <img :src="trainingElearningServiceBaseUrl + 'storage/uploads/circular-publications/' + circular.upload_guidelines" style="width: 190px; display: block; margin: 0 auto; height: 120px; border-radius: 0px !important;"> -->
                          <a target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/circular-publications/' + circular.upload_guidelines" download>{{$t('globalTrans.view_download')}}</a>
                          <!-- (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/circular-publications/' + circular.upload_guidelines" download>{{$t('globalTrans.view_download')}}</a> ) -->
                      </b>
                      </b-td>
                  </b-tr>
              </b-table-simple>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 15px;
}
</style>
<script>
// import ExportPdf from './export-pdf_details'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTrainingPortal.vue'

export default {
    components: {
        ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
        this.circular = this.item
    },
    data () {
        return {
          circular: [],
          slOffset: 1,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
    },
    methods: {
        // pdfExport () {
        //     const reportTitle = this.$i18n.locale === 'en' ? 'Port Wise Allocation Management' : 'বন্দর অনুযায়ী বরাদ্দের ব্যবস্থাপনা'
        //     ExportPdf.exportPdfDetails(reportTitle, this.circular, this)
        // }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  .reportTitle {
        font-weight: bold;
        border: 1px solid;
        padding: 8px 15px 8px 15px;
        border-radius: 11px;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
